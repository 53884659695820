export default function (BaseURL: string) {
    const Default = {
        defaultProfilePicture: `assets/images/defaultProfilePicture.png`,
        defaultDogPicture: `assets/images/defaultDogPicture.jpg`,
    }

    const Hubs = {
        message: `${BaseURL}/MessageHub`,
        notification: `${BaseURL}/NotificationHub`
    }

    const Base = {
        Comment: `${BaseURL}/Comment`,
        Post: `${BaseURL}/Post`,
        Events: `${BaseURL}/EventInfo`,
        Animal: `${BaseURL}/Animal`,
        User: `${BaseURL}/User`,
        Auth: `${BaseURL}/Auth`,
        File: `${BaseURL}/File`,
        Friend: `${BaseURL}/Friendship`,
        Like: `${BaseURL}/Like`,
        Room: `${BaseURL}/Room`,
        Notification: `${BaseURL}/Notification`,
        Map: `${BaseURL}/Map`,
    }

    const API = {
        basePath: BaseURL,
        animal: {
            getAll: Base.Animal,
            register: Base.Animal,
            update: Base.Animal,
            delete: Base.Animal,
            profileImage: {
                update: Base.Animal + '/Gallery/ProfilePicture'
            },
            gallery: {
                image: {
                    get: Base.Animal + '/Gallery/Image',
                    add: Base.Animal + '/Gallery/Image/Add',
                    delete: Base.Animal + '/Gallery/Image/Delete'
                }
            },
            getFamilyTree: Base.Animal + '/Tree'
        },
        authenticate: {
            getAuthentication: Base.Auth,
            validateEmail: Base.Auth + '/Validate',
            resetPasswordRequest: Base.Auth + '/ResetPasswordRequest',
            resetPassword: Base.Auth + '/ResetPassword',
            refreshToken: Base.Auth + '/Refresh',
        },
        file: {
            upload: Base.File + '/Upload',
            save: Base.File + '/Save',
            getOne: Base.File
        },
        like: {
            toggle: Base.Like + '/Toggle'
        },
        user: {
            getUser: Base.User,
            friends: Base.User + '/Friends',
            animals: Base.User + '/Animals',
            register: Base.User + '/Register',
            update: Base.User + '/Update',
            getData: Base.User + '/Sok/Pnr',
            getNotifications: Base.User + '/Notifications'
        },
        map: {
            markers: {
                getAll: Base.Map + '/getMapMarkers',
                getTotalLikes: Base.Map + '/getMapMarkerLike',
            }
        },
        notification: {
            update: Base.Notification + '/Update',
            markAsRead: Base.Notification + '/Read'

        },
        comment: {
            forPost: Base.Comment + '/Post',
            getSubcomments: Base.Comment + '/SubComment',
            postNew: Base.Comment
        },
        post: {
            getOne: Base.Post + '/Single',
            getAll: Base.Post,
            create: Base.Post,
            update: Base.Post,
            delete: Base.Post,
        },
        events: {
            create: Base.Events,
            getAllEvents: Base.Events,
            getEventById: Base.Events + '/Single',
            update: Base.Events,
            delete: Base.Events,
        },
        messages: {
            base : Base.Room,
            replyToMessage: Base.Room + '/Reply',
            userSuggestions: Base.User + '/Suggestions',
            room: {
                create: Base.Room + '/GetRoom',
                getMessages: Base.Room + '/Messages',
                getCurrentRooms: Base.User + '/Rooms',
                user: Base.Room + '/User',
                findShared: Base.Room + '/Shared'
            }
        },
        friendship: {
            requests: Base.Friend + '/Requests',
            getSingle: Base.Friend,
            create: Base.Friend,
            accept: Base.Friend + '/Accept',
            update: Base.Friend,
            delete: Base.Friend + '/Remove',
        }
    }
    return { Default, API, Hubs }
}
