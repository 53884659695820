import { inject, Injectable } from "@angular/core";
import { Observable, BehaviorSubject, AsyncSubject, throwError } from "rxjs";
import { ApiService } from "../../shared/service/http.service";
import { environment } from "src/environments/environment";
import { Animal } from "src/app/shared/model/animal";
import { FamilyMember } from "src/app/shared/model/family-member";
import { RegisterAnimal } from "src/app/shared/model/register-animal.model";
import { GalleryImage } from "src/app/shared/types/GalleryImage";
import { UploadFile } from "src/app/shared/model/upload-file";

interface FamilyMemberIn {
  id: number;
  gender: string;
  regnr: string;
  colors: string | null;
  titles: string | null;
  hundnamn: string;
}
interface FamilyMemberOut {
  id: number;
  gender: string;
  regnr: string;
  colors: string | null;
  titles: string | null;
  hundnamn: string;
  position: number;
  childPosition: number;
  generation: number;
  parents?: FamilyMemberOut[];
}
interface ArraysObject {
  [key: string]: FamilyMemberIn[];
}
@Injectable({
  providedIn: "root",
})
export class AnimalProfileService {
  private animalIdSource = new BehaviorSubject("");
  currentAnimalId = this.animalIdSource.asObservable();

  private animalApiRoutes = environment.api.animal;
  private userApiRoutes = environment.api.user;

  apiService = inject(ApiService)

  private animalsSource: BehaviorSubject<Animal[]> = new BehaviorSubject<Animal[]>([]);
  public currentAnimals: Observable<Animal[]> = this.animalsSource.asObservable();

  private allAnimalsSource: BehaviorSubject<Animal[]> = new BehaviorSubject<Animal[]>([]);
  public allCurrentAnimals: Observable<Animal[]> = this.allAnimalsSource.asObservable();

  private dogFamilyTree = new AsyncSubject();

  setDogFamilyTree(dogFamily: FamilyMember) {
    this.dogFamilyTree.next(dogFamily);
    this.dogFamilyTree.complete();
  }
  getDogFamilyTree(): Observable<any> {
    return this.dogFamilyTree.asObservable();
  }

  saveAnimalProfile(animal: Animal): Observable<Animal> {
    const queryParams = { model: animal };
    const updatedAnimal = this.apiService.Post<Animal>(this.animalApiRoutes.update, queryParams);
    return updatedAnimal;
  }

  registerAnimal(animal: RegisterAnimal): Observable<Animal> {
    const updatedAnimal = this.apiService.Post<Animal>(this.animalApiRoutes.register, animal);

    return updatedAnimal;
  }

  updateAnimal(updatedAnimal: Animal): Observable<Animal> {
    const animals = this.animalsSource.getValue();
    const animalIndex = animals.findIndex((animal) => animal.id === updatedAnimal.id);
    if (animalIndex !== -1) {
      animals[animalIndex] = updatedAnimal;
      this.animalsSource.next(animals);
      return this.apiService.Put<Animal>(this.animalApiRoutes.update, updatedAnimal);
    }
    return throwError(() => new Error(`Animal with ID ${updatedAnimal.id} not found`));
  }



  DeleteAnimal(AnimalId: number): Observable<any> {
    const animals = this.animalsSource.getValue();
    const animalIndex = animals.findIndex((animal) => animal.id === AnimalId);
    if (animalIndex !== -1) {
      animals.splice(animalIndex, 1);
      this.animalsSource.next(animals);
      const urlWithId = this.animalApiRoutes.delete + '/' + AnimalId;
      return this.apiService.Delete(urlWithId);
    }
    return throwError(() => new Error(`Animal with ID ${AnimalId} not found`));
  }


  public setAnimals(Animals: Animal[]) {
    this.animalsSource.next(Animals);
  }

  public getAnimals() {
    return this.animalsSource.value;
  }

  updateAnimalProfileImage(animalId: number, uploadFile: UploadFile) {
    return this.apiService.Post<UploadFile>(this.animalApiRoutes.profileImage.update, uploadFile, { params: { animalId: animalId } });
  }

  getUserAnimals(ownerId?: number) {
    this.apiService
      .Get<[]>(this.userApiRoutes.animals, { params: { ownerId } })
      .subscribe((res) => {
        if (res) {
          this.setAnimals(res);
        }
      });
  }

  getAllAnimals() {
    this.apiService.Get<[]>(this.animalApiRoutes.getAll).subscribe((res) => {
      if (res) {
        this.setallAnimals(res);
      }
    });
  }

  public setallAnimals(Animals: Animal[]) {
    this.allAnimalsSource.next(Animals);
  }

  public getallAnimals() {
    return this.allAnimalsSource.value;
  }

  setAnimalId(animalId: string) {
    this.animalIdSource.next(animalId);
  }



  getAnimalFamilyTree(id: number): Observable<any> {
    return this.apiService.Get<any>(this.animalApiRoutes.getFamilyTree, { params: { id } });
  }

  registerDog(registerAnimal: RegisterAnimal): Observable<any> {
    return this.apiService.Post(this.animalApiRoutes.register, registerAnimal);
  }

  getFamilyGenerations(num: number): number {
    const nestedNum = 2;
    let levels = 1;
    let newNum = num - 1;
    let multiplier = 1;
    while (newNum > 0) {
      newNum = newNum - nestedNum * multiplier;
      multiplier = multiplier * nestedNum;
      levels++;
    }
    return levels;
  }
  sliceArrayInHalf(arr: any) {
    if (arr.length % 2 == 0) {
      const half1 = arr.slice(0, arr.length / 2);
      const half2 = arr.slice(arr.length / 2, arr.length);
      return [half1, half2];
    } else {
      return 'error cann"t slice in half';
    }
  }

  combineAnimalFamilies(arr: FamilyMemberIn[]) {
    const genrations = this.getFamilyGenerations(arr.length);
    const families: FamilyMemberOut[] = [];
    families.push({
      ...arr.shift()!,
      generation: 1,
      position: 1,
      childPosition: 0,
      parents: [],
    });

    // In Case arr has only child and parents
    if (arr.length == 2) {
      for (let index in arr) {
        families.push({
          ...arr[index],
          generation: 2,
          position: Number(index) + 1,
          childPosition: 1,
        });
      }
    } else {
      let arrays: ArraysObject = {};
      for (let gen = 2; gen <= genrations; gen++) {
        if (gen == genrations) {
          // Connect last parents to their children
          let position = 1;
          for (let [key, value] of Object.entries(arrays)) {
            for (let j = 0; j < 2; j++) {
              const name = `${position}`;
              families.push({
                ...value.shift()!,
                generation: gen,
                position: Number(`${position}`),
                childPosition: Number(`${key}`),
              });
              position++;
            }
          }
        } else if (gen == 2) {
          let position = 1;
          const arrs = this.sliceArrayInHalf(arr);
          for (let j = 0; j < arrs.length; j++) {
            const name = `${position}`;
            families.push({
              ...arrs[j].shift(),
              generation: gen,
              position: Number(`${position}`),
              childPosition: Number(gen - 1),
              parents: [],
            });
            position++;
            arrays[name] = arrs[j];
          }
        } else {
          const savedArrays = { ...arrays };
          arrays = {};
          let position = 1;
          for (let [key, value] of Object.entries(savedArrays)) {
            const arrs = this.sliceArrayInHalf(value);
            for (let j = 0; j < arrs.length; j++) {
              console.log(arrs[j]);
              const name = `${position}`;
              families.push({
                ...arrs[j].shift(),
                generation: gen,
                position: Number(`${position}`),
                childPosition: Number(`${key}`),
                parents: [],
              });
              position++;
              arrays[name] = arrs[j];
            }
          }
        }
      }
    }
    families.sort((a, b) => b.generation - a.generation);
    for (let i = 0; i < families.length; i++) {
      const parent = families!.find(
        (obj) =>
          obj!.position == families[i]?.childPosition &&
          obj!.generation == families[i]?.generation - 1
      );
      if (parent) {
        parent.parents?.push(families[i]);
      }
    }
    const dogTree = families[families.length - 1];
    console.log("dog Family tree: ", families[families.length - 1]);
    this.setDogFamilyTree(dogTree);
  }
  getImagesForAnimal(id: number) {
    return this.apiService.Get<GalleryImage[]>(this.animalApiRoutes.gallery.image.get, { params: { animalId: id } });
  }

  public existAnimalId() {
    if (Number(this.currentAnimalId) == 0) {
      return false;
    } else {
      return true;
    }
  }
}
